//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import moment from 'moment'
import CommentLoadingSkeleton from '@/components/CommentSection/CommentLoadingSkeleton'
export default {
    name: 'CommentSection',
    props: ['post_id'],
    components: {
        CommentLoadingSkeleton,
        EmptyMessage: () => import('@/components/EmptyMessage'),
        CommentUser: () => import('@/components/CommentSection/CommentUser')
    },
    created() {
        if (this.$route.name == 'Post') {
            const context = this;
            this.retrieveComments(this.currentPage)
            this.realTimeCommentEngine = setInterval(() => {
                context.getLatestComments(this.currentPage)
            }, 1000);
        } else {
            this.retrieveComments(this.currentPage) 
        }
    },
    data() {
        return {
            isSendingComment: false,
            isLoading: true,
            comment: '',
            comments: [],
            currentPage: 1,
            maxNumberOfPages: 1,
            overallCount: 1,
            hasComments: true,
            hasLoadedMoreComments: false,
            currentTimestamp: 0,
            newComments: 0,
            realTimeCommentEngine: null
        }
    },
    computed: {
        getComments() {
            return this.comments;
        },
        hasMoreComments() {
            if (this.maxNumberOfPages > this.currentPage) {
            return true;
            }
            return false;
        },
        getRemainingComments() {
            let rem = this.overallCount - this.comments.length;
            if (rem < 0) {
            return 0;
            } 
            return rem;
        }
    },
    beforeDestroy() {
        clearInterval(this.realTimeCommentEngine);
    },
    methods: {
        sendComment() {
            if (this.comment == '') {
                this.$q.notify({
                    message: 'Please enter something..',
                    color: 'warning',
                    icon: 'warning'
                });
                return;
            }
            this.isSendingComment = true;
            this.$http({
                url: 'posts/' + this.post_id + '/comment',
                method: 'POST',
                data: {
                    body: this.comment
                }
            }).then((response) => {
                this.isSendingComment = false;
                if (response.data['status'] == 'comment_created') {
                    if (this.$route.name !== 'Post') {
                        var comment = response.data['body']['comment'];
                        comment.currentTimestamp = moment().unix();
                        this.comments.unshift(response.data['body']['comment']);
                        this.hasComments = true;
                    }
                    this.$q.notify({
                        message: 'You have commented on this post.',
                        icon: 'chat'
                    });
                    this.comment = ''
                } else {
                    this.$q.notify({
                        message: 'Something went wrong while posting your comment. Please try again.',
                        color: 'negative',
                        icon: 'error'
                    });
                }
            })
        },
        retrieveComments(page) {
            this.$http({
                url: 'posts/' + this.post_id + '/comments/' + page ,
                method: 'GET'
            }).then((response) => {
                this.isLoading = true;
                switch(response.data['status']) {
                    case 'comments_retrieved':
                        var newComments = response.data['body']['comments'];
                        this.hasComments = true;
                        this.currentPage = response.data['body']['pagination']['currentPage'];
                        this.maxNumberOfPages = response.data['body']['pagination']['maxNumberOfPages'];
                        this.overallCount = response.data['body']['pagination']['overallCount'];
                        this.isLoading = false;
                        newComments.forEach((comment) => {
                            comment.image = '/' + comment.image
                            this.comments.push(comment);
                        });
                        if (this.$route.name == 'Post') {
                            if (this.comments[0]) {
                                this.currentTimestamp = this.comments[0].currentTimestamp;
                            }
                        }
                    break;
                    case 'no_comments':
                        this.hasComments = false;
                        this.isLoading = false;
                    break;
                }
            });
        },
        nextPage() {
            if (this.hasMoreComments) {
                this.retrieveComments(this.currentPage + 1);
                this.hasLoadedMoreComments = true;
            }            
        },
        closeCommentSection() {
            this.comments = [];
            this.currentPage = 1;
            this.hasLoadedMoreComments = false;
            this.retrieveComments(this.currentPage);            
        },
        getLatestComments() {
            this.$http({
                url: 'posts/' + this.post_id + '/comments/1?timestamp=' + this.currentTimestamp,
                method: 'GET'
            }).then((response) => {
                switch (response.data['status']) {
                    case 'comments_retrieved':
                        this.newComments = this.newComments + response.data['body']['comments'].length;
                        var latestComments = response.data['body']['comments'];
                        latestComments.forEach((comment) => {
                            comment.image = '/' + comment.image
                            this.comments.unshift(comment);
                        });

                        this.currentTimestamp = this.comments[0].currentTimestamp;
                        if (!this.hasComments) {
                            this.hasComments = true;
                        }
                        if (this.newComments > 10) {
                            this.newComments = 0;
                            this.comments.pop();
                        }
                    break;
                }
            })
        }
    }
}
