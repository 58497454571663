//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: 'ReactButton',
    props: ['post_id'],
    mounted() {
        this.getReaction();
    },
    data() {
        return {
            isLoaded: false,
            currentReact: 0,
            hasReactions: false,
            reactions: [
                {
                    id: 0,
                    name: 'No Reaction',
                    url: require('@/assets/reactions/0.png')
                },
                {
                    id: 1,
                    name: 'Liked',
                    url: require('@/assets/reactions/like.png')
                },
                {
                    id: 2,
                    name: 'Trophy',
                    url: require('@/assets/reactions/trophy.png')
                },
                {
                    id: 3,
                    name: 'Play',
                    url: require('@/assets/reactions/golfer.png')
                },
                {
                    id: 4,
                    name: 'Surprise',
                    url: require('@/assets/reactions/surprised.png')
                }
            ]
        }
    },
    methods: {
        clearReaction() {
            this.$http({
                url: 'posts/' + this.post_id + '/react?type=0',
                method: 'POST'
            }).then((response) => {
            switch (response.data['status']) {
                case 'reacted_successfully':
                    this.hasReactions = false;
                    this.currentReact = 0;
                     this.$store.commit('reacts/save', {
                        id: this.post_id,
                        value: this.currentReact
                    });
                break;
            }
            })
        },
        sendReaction() {
            this.$http({
                url: 'posts/' + this.post_id + '/react?type=' + this.currentReact,
                method: 'POST'
            }).then((response) => {
            switch (response.data['status']) {
                case 'reacted_successfully':
                    this.hasReactions = true;
                    this.$store.commit('reacts/save', {
                        id: this.post_id,
                        value: this.currentReact
                    });
                    this.$q.notify({
                       message: `<center><img src="`+ this.reactions[this.currentReact].url + `" style="width:64px; height:64px"/>
                       <br>
                        <h3 style='color:black'>` + this.reactions[this.currentReact].name  + `!</h3></center>
                       `,
                       html: true,
                       color: 'white'
                    })
                break;
            }
            })
        },
        getReaction() {

            // Before connecting to server, check the local cache first.
            let react = this.$store.getters['reacts/read'](this.post_id);
            if (react !== undefined) {
                this.hasReactions = true;
                this.currentReact = react.value;
                this.isLoaded = true;
                return;
            }
            
            this.$http({
                url: 'posts/' + this.post_id + '/reacts?type=user',
                method: 'GET'
            }).then((response) => {
            this.isLoaded = true;
            switch (response.data['status']) {
                case 'reaction_retrieved':
                    this.hasReactions = true;
                    this.currentReact = response.data['body']['react']['type'];
                    this.$store.commit('reacts/save', {
                        id: this.post_id,
                        value: this.currentReact
                    });
                break;
                case 'no_reaction':
                    this.hasReactions = false;
                    this.currentReact = 0;
                    this.$store.commit('reacts/save', {
                        id: this.post_id,
                        value: this.currentReact
                    });
                break;
            }
            });
        }
    }
}
