//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import moment from 'moment'
import ReactButton from '@/components/Posts/ReactButton'
import CommentSection from '@/components/CommentSection/CommentSection'
import Reporter from '@/components/Reporter/Reporter'
export default {
  name: 'Post',
  props: [
    'post'
  ],
  created() {
    if (this.post.user_id != this.$store.state.user.id) {
      this.$http({
          url: 'profiles/' + this.post.user_id + '/getProfilePhoto',
          method: 'GET' 
        }).then((response) => {
          if (response.data['status'] == 'profile_photo_retrieved') {
            if (response.data['body']['profile_photo'] != 'not_available') {
              this.profile_photo = response.data['body']['file_url'];
            }
          }
      });
    } else {
      this.profile_photo = this.$store.state.user.profile_photo;
    }
    this.get_medias();
    this.countReactions();

    if (this.$route.name == 'Post') {
      this.showCommentSection = true;
    }
  },
  components: {
    Media: () => import('@/components/Gallery/Media'),
    SoloMedia: () => import('@/components/Posts/SoloMedia'),
    SharedEntity: () => import('@/components/Posts/SharedEntity'),
    ReactButton,
    CommentSection,
    Reporter
  },
  data() {
    return {
      isDeleted: false,
      isReactionsCounted: false,
      showCommentSection: false,
      hasMedias: false,
      medias: [],
      maxNumberOfPages: 1,
      overallCount: 0,
      profile_photo: require('@/assets/default_profile.webp'),
      currentReactType: 1,
      reactionLabel: 'Counting reactions..',
      format_created_at: this.post.created_at.date ? this.post.created_at.date: this.post.created_at,
      format_time_at: this.post.created_at.date ? this.post.created_at.date: this.post.created_at,
      showReporter: false
    }
  },
  methods: {
      shiftMedias(id) {
        for (var i=0; i < this.medias.length; i++) {
          if (this.medias[i].id === id) {
            this.medias.splice(i, 1);
          }
        }
      },
      delete_post() {
        this.$q.dialog({
          title: "There's no turning back..",
          message: 'Are you sure that you want to delete this post?',
          cancel: true,
          persistent: true,
          ok: {
            label: 'Yes'
          }
        }).onOk(() => {
          this.$q.loading.show({
            message: 'Deleting post..'
          });
          this.$http({
            url: 'posts/' + this.post.id,
            method: 'DELETE'
          }).then((response) => {
            if (response.data['status'] == "post_deleted") {
              this.isDeleted = true; 
              this.$q.notify({
                message: 'The post has been successfully deleted.'
              })
            }
          })
          this.$q.loading.hide();
        })      
      },
      get_medias()
      {
        this.$http({
          url: 'posts/' + this.post.id + '/medias',
          method: 'GET'
        }).then((response) => {
          switch (response.data['status']) {
            case 'medias_retrieved':
              this.hasMedias = true;
              this.medias = response.data['body']['medias'];
            break;
          }
        })
      },
      countReactions() {
            // Before connecting to server, check the local cache first.
            let react = this.$store.getters['reacts/read'](this.post.id);
            if (react !== undefined) {
                if (react.count !== undefined) {
                 
                  if (react.count == 0) {
                    this.isReactionsCounted = false;
                    return;
                  }
                  this.isReactionsCounted = true;
                  if (react.count == 1) {
                    this.reactionLabel = '1 person reacted on this post.'
                  } else {
                    this.reactionLabel = 'There are ' + react.count + ' golfers reacted on this post.'
                  }
                  return;
                }
            }
            this.$http({
                url: 'posts/' + this.post.id + '/reacts?type=count_only',
                method: 'GET'
            }).then((response) => {
            switch (response.data['status']) {
                case 'reacts_counted':
                    this.isReactionsCounted = true;
                    this.$store.commit('reacts/save', {
                        id: this.post.id,
                        count:  response.data['body']['react_count']
                    });
                    if (response.data['body']['react_count'] == 1) {
                      this.reactionLabel = '1 person reacted on this post.'
                    } else {
                      this.reactionLabel = 'There are ' + response.data['body']['react_count'] + ' golfers reacted on this post.'
                    }
                break;
                case 'no_reacts':
                  this.isReactionsCounted = false;
                   this.$store.commit('reacts/save', {
                      id: this.post.id,
                      count: 0
                  });
                break;
            }
            });
      }
    },

    filters:{
      filterLinks(post){
        var post_array = []
        const domain_names = [
          '.com','.net','.ph','.org','.dev','.gov','.edu','.asp'
        ]
        post = post.split(" ")
        post.map((x) => {
          if( x.includes('http') || ( x.includes('http') && domain_names.some(domain => x.includes(domain))) ){
            x = "<a href='"+x+"' target='_blank'>"+x+"</a>"
          }
          post_array.push(x)
        })
        post_array =  post_array.join(" ")
        return post_array
      },

      formatePostDate(date){
        return moment(date).format('LL');
      },

      formatPostTime(date){
        return moment(date).format('LT');
      }
    }
}
