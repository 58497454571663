import { render, staticRenderFns } from "./Post.vue?vue&type=template&id=3b7fb4e5&"
import script from "./Post.vue?vue&type=script&lang=js&"
export * from "./Post.vue?vue&type=script&lang=js&"
import style0 from "./Post.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import {QCard,QCardSection,QAvatar,QIcon,QBtn,QMenu,QList,QItem,QItemSection,QCardActions,QDialog} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QCard,QCardSection,QAvatar,QIcon,QBtn,QMenu,QList,QItem,QItemSection,QCardActions,QDialog})
